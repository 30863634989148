
















































































import { Component, Vue } from "vue-property-decorator";
import { apiNoticeDetail, apiNoticeSet } from "@/api/application/sms";
import { apiEmailSmtpConfig, apiSetEmailSmtpConfig } from '@/api/setting/setting'
@Component
export default class Buyers extends Vue {
    detail: any = {

    };

    // 提交保存
    onSubmit() {
      apiSetEmailSmtpConfig(this.detail)
            .then((res) => {

                this.$message.success("设置成功!");
            })
            .catch(() => {
                this.$message.error("数据请求失败，刷新重载!");
            });
    }

    // 获取详情
    getNoticeDetail() {
      apiEmailSmtpConfig()
            .then((res) => {
              console.log(res,'resres')
                this.detail = res;
            })
            .catch(() => {
                this.$message.error("数据请求失败，刷新重载!");
            });

    }


    created() {
      this.getNoticeDetail()
    }
}
